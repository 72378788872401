/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

function SEO({ description, sharingImage, lang, meta, title }) {
  const { globalSeoSettings } = useStaticQuery(
    graphql`
      query {
        globalSeoSettings: storyblokEntry(
          field_component: { eq: "globalSeoSettings" }
        ) {
          content
        }
      }
    `
  );

  const globalSeoSettingsParsed =
    globalSeoSettings && JSON.parse(globalSeoSettings.content);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={`%s${
          globalSeoSettingsParsed
            ? globalSeoSettingsParsed.titleSuffix
            : ' | Sertus'
        }`}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `og:image`,
            content:
              sharingImage ||
              (globalSeoSettingsParsed &&
                globalSeoSettingsParsed.sharingImage &&
                globalSeoSettingsParsed.sharingImage.filename) ||
              '',
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: '',
          },
          {
            name: `twitter:description`,
            content: '',
          },
        ].concat(meta)}
      />
      <Helmet>
        {/* Google Ads */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16943169983"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-16943169983');
        `}</script>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/5924647.js"
        ></script>
      </Helmet>
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
